'use client'

// next core
import { useRouter } from 'next/navigation'

// react core
import { useEffect, useState } from 'react'

// styles
import {
   StyledErrorContainer,
   StyledErrorHeading,
   StyledBrokenApiImage,
   StyledErrorDescription,
   StyledRetryCta,
   StyledErrorStatus,
   StyledErrorCode,
   StyledErrorTopContainer,
   StyledErrorBottomContainer,
   StyledRetryCtaIcon,
} from './error.style'

// types
import { ErrorProps } from './error.d'

// images
import BrokenApiErrorScenario from '@/public/images/broken_api_error_scenario.svg?url'
import retryIcon from '@/public/images/retry.svg'
import { configSlice, useDispatch } from '@/store/redux'
export default function Error({ error, reset }: ErrorProps) {
   const dispatch = useDispatch()
   const router = useRouter()
   const [errorDetail, setErrorDetail] = useState<any>()
   useEffect(() => {
      setErrorDetail(error)
   }, [error])

   const onRetryHandler = () => {
      dispatch(configSlice.actions.resetConfigAsync())
      window.location.reload()
   }

   return (
      <StyledErrorContainer tabIndex={-1}>
         <StyledBrokenApiImage
            src={BrokenApiErrorScenario}
            width={90.78}
            height={96}
            priority
            alt="Application Error Icon"
            aria-hidden={true}
         />
         <StyledErrorTopContainer aria-live="assertive">
            {errorDetail?.data?.title && (
               <StyledErrorHeading component="h1" tabIndex={0}>
                  <span dangerouslySetInnerHTML={{ __html: errorDetail?.data.title }}></span>
               </StyledErrorHeading>
            )}
            {errorDetail?.data?.description && (
               <StyledErrorDescription fontdefination="smallRegular" tabIndex={0}>
                  <span dangerouslySetInnerHTML={{ __html: errorDetail?.data.description }}></span>
               </StyledErrorDescription>
            )}
         </StyledErrorTopContainer>

         <StyledRetryCta
            startIcon={<StyledRetryCtaIcon src={retryIcon} width={20} height={20} alt="" aria-hidden="true" />}
            onClick={onRetryHandler}
            aria-label="Retry"
            tabIndex={0}
         >
            RETRY
         </StyledRetryCta>
         <StyledErrorBottomContainer tabIndex={0}>
            {errorDetail?.code && (
               <StyledErrorStatus>
                  <span>Error Status Code:&nbsp;</span>
                  <span>{errorDetail?.code}</span>
               </StyledErrorStatus>
            )}
            {errorDetail?.message && (
               <StyledErrorCode>
                  <span>Error Code:&nbsp;</span>
                  <span>{errorDetail?.message}</span>
               </StyledErrorCode>
            )}
         </StyledErrorBottomContainer>
      </StyledErrorContainer>
   )
}
